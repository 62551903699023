import React, { useRef } from 'react';

import {
  galleryGridStylesMapping, useActiveIndex, gallerySizeContainer,
  commonGalleryButtonMobileStyles,
} from '../utils';
import { decreaseIndex, increaseIndex, updateScrollImage } from '../utils/utils';
import {
  Image, ImageType, Button, TriggerButton, Counter, ResponsiveImageSize,
} from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

interface GalleryGridProps {
  images: ImageType[];
  onClickOpenMore: () => void;
  onClickImage: (id: number) => void;
  quality?: number;
}

const getImageWidth = (imagesForGrid: number): ResponsiveImageSize => {
  if (imagesForGrid === 1) {
    return [ 400, 600, 600 ];
  }

  return [ 400, 300, 300 ];
};

const getImageHeight = (imagesForGrid: number, index: number): ResponsiveImageSize => {
  if (index === 0 || imagesForGrid <= 2) {
    // Full height images
    return [ 275, 300, 456 ];
  }

  // Half height images
  return [ 275, 200, 200 ];
};

// Grid with images, maximum we have 5 for desktop/tablet.
export const GalleryGrid: React.FC<React.PropsWithChildren<GalleryGridProps>> = ({
  images,
  onClickImage,
  onClickOpenMore,
  quality,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const loadedImages = useRef<Record<number, boolean>>({});

  if (!images.length) {
    return null;
  }

  const size = images.length;
  const activeIndex = useActiveIndex(containerRef);

  const imagesForGrid = Math.min(images.length, Object.keys(galleryGridStylesMapping).length);
  const remainingImages = images.length - imagesForGrid;

  const isImagePrepared = (index: number) => (
    activeIndex === index
      || increaseIndex(activeIndex, size) === index
      || decreaseIndex(activeIndex, size) === index
  );

  return (
    <div
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: [ 0, '8' ],
        ...gallerySizeContainer,
      }}
    >
      <Button
        data-id="fullscreen-gallery-mobile-previous"
        variant="Secondary"
        size="36"
        icon="Actions/MovePrevious"
        onClick={() => updateScrollImage({
          containerRef,
          newActiveIndex: decreaseIndex(activeIndex, size),
        })}
        sx={{
          ...commonGalleryButtonMobileStyles,
          left: '3xs',
        }}
      />
      <div
        sx={{
          position: 'relative',
          paddingBottom: [ '64%', '50%' ],
        }}
      >
        <div
          className="hide-scrollbars"
          ref={containerRef}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: [ 'flex', 'grid' ],
            flexWrap: [ 'nowrap', null ],
            gap: [ 0, '3xs' ],
            gridTemplateColumns: [ null, '2fr 1fr 1fr' ],
            gridTemplateRows: [ null, 'auto auto' ],
            overflowX: [ 'auto', 'hidden' ],
            overflowY: 'hidden',
            scrollSnapType: [ 'x mandatory', 'none' ],
            touchAction: 'pan-x pan-y',
            ...gallerySizeContainer,
            ...galleryGridStylesMapping[imagesForGrid],
            '& > *': {
              backgroundColor: '#E6E3DC',
              width: '100%',
              scrollSnapAlign: 'start',
              scrollSnapStop: 'always',
              flexShrink: [ 0, null ],
            },
          }}
        >
          {images.map((image, index) => (
            <TriggerButton
              key={index}
              onTrigger={() => onClickImage(index)}
              sx={{
                gridArea: `image${index + 1}`,
                display: [
                  'block',
                  index < imagesForGrid ? 'block' : 'none',
                ],
                overflow: 'hidden',
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                  zIndex: 2,
                  background: 'rgba(0, 0, 0, 0.3)',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  display: 'block',
                },
                '&:hover': {
                  '&:after': {
                    // we hide hover effect on mobile
                    opacity: [ 0, 1 ],
                  },
                },
              }}
            >
              <Image
                src={image.url}
                alt={image.description}
                width={getImageWidth(imagesForGrid)}
                height={getImageHeight(imagesForGrid, index)}
                lazy={!isImagePrepared(index) && !loadedImages.current[index] && index > 4}
                onLoadCallback={() => {
                  loadedImages.current[index] = true;
                }}
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                }}
                quality={quality}
              />
            </TriggerButton>
          ))}
        </div>
      </div>
      <Button
        data-id="fullscreen-gallery-mobile-next"
        variant="Secondary"
        size="36"
        icon="Actions/MoveNext"
        sx={{
          ...commonGalleryButtonMobileStyles,
          right: '3xs',
        }}
        onClick={() => updateScrollImage({
          containerRef,
          newActiveIndex: increaseIndex(activeIndex, size),
        })}
      />
      {remainingImages > 0 && (
        <Button
          size="36"
          variant="Outline"
          sx={{
            display: [ 'none', 'block' ],
            position: 'absolute',
            right: 'xs',
            bottom: 'xs',
            zIndex: 2,
            boxShadow: 'elevationFloating',
            borderStyle: 'solid',
            borderWidth: 'outlinedStrokeWeight',
            borderColor: 'strokeDark',
          }}
          onClick={onClickOpenMore}
        >
          {t('xPhotos', { count: remainingImages })}
        </Button>
      )}
      <Counter
        variant="Light"
        currentCount={activeIndex + 1}
        total={images.length}
        size="28"
        sx={{
          display: [ 'flex', 'none' ],
          position: 'absolute',
          bottom: 'xs',
          left: '50%',
          zIndex: 3,
          transform: 'translateX(-50%)',
        }}
      />
    </div>
  );
};
